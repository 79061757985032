<template>
  <div>
    
    <v-data-table :headers="headers"
                  dense
                  fixed-header
                  :options.sync="pageOptions"
                  :server-items-length="totalElements"
                  :disable-sort="true"
                  :hide-default-header="$vuetify.breakpoint.mobile"
                  :footer-props="{
      disableItemsPerPage: true,
      itemsPerPageOptions: [itemsPerPage],
    }"
                  :items="elements" :loading="loading">
      <template v-slot:item.id="{item}">
        <strong>#{{ item.id }}</strong>
      </template>
      <template v-slot:item.tipoAluno="{item}">
        <span :class="corTipoAluno(item.tipoAluno)">{{ item.tipoAluno }}</span>
      </template>
      <template v-slot:item.tpAluguel="{item}">
        <span>{{item.tpAluguel === 'M' ? 'Mensal' : 'Avulso'}}</span>
      </template>
      <template v-slot:item.idFatura="{item}">
        <span>{{dadosfatura(item)}}</span>
      </template>
      <template v-slot:item.status="{item}">
        <span>{{item.status === 'A' ? 'Ativo' : 'Inativo'}}</span>
      </template>
      <template v-slot:item.valAluguel="{item}">
        <span>R$ {{item.valAluguel}}</span>
      </template>
      <template v-slot:item.dias="{item}">
        <span>{{item.tpAluguel === 'M' ? item.dias.map(d => d.descricao).join(', ') : '--'}}</span>
      </template>
      <template v-slot:item.data="{item}">
        <span>{{item.data | moment('DD/MM/YYYY')}}</span>
      </template>
      <template v-slot:item.edit="{item}">
        <v-btn @click.prevent="showMenu($event, item)" small icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>


      <template v-slot:body="{items, isSelected, select}" v-if="$vuetify.breakpoint.mobile">
        <v-list dense class="pa-0">
          <v-list-item v-for="item of items" :key="item.id"
                       class="list-item">
            <v-list-item-avatar>
              #{{ item.id }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{item.idCliente_nome}}
                <span :class="corTipoAluno(item.tipoAluno)">({{item.tipoAluno}})</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                Locação {{item.tpAluguel === 'M' ? 'Mensal no(s) dia(s): ' + item.dias.map(d => d.descricao).join(', ') : 'Avulso'}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{item.idLocacao_descricao}}
              </v-list-item-subtitle>             
            </v-list-item-content>
            <v-list-item-action-text>
              <v-chip color="primary" class="mr-2" input-value="false" small>R$ {{item.valAluguel}}</v-chip>
            </v-list-item-action-text>
            <v-list-item-action-text>
              <v-chip small input-value="false" outlined>
                {{item.status === 'A' ? 'Ativo' : 'Inativo'}}
              </v-chip>
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import {findAllClienteLogado} from '../../../api/aluguel';
import ResponsiveMenu from "../../app/ResponsiveMenu";
import * as moment from 'moment';

/**
 * @emits click:edit
 * @emit click:remove
 */
export default {
  name: "AluguelsDataTable",
  components: {ResponsiveMenu},
  props: {
    searchValues: {
      type: Object,
    },
  },
  data() {
    return {
      selectedItem: null,
      menuOptions: [
        
      ],
      menu: {
        positionX: 0,
        positionY: 0,
        show: false
      },
      loading: false,
      error: null,
      pageOptions: {
        page: 1
      },
      itemsPerPage: 0,
      totalElements: 0,
      elements: [],
      headers: [
        {
          value: 'id',
          text: 'ID',
          align: 'start',
          width: "5%"
        },
        {
          text: 'Tipo',
          value: 'tipoAluno',
          align: 'start',
          width: "5%"
        },
        {
          text: 'Tipo Locação',
          value: 'tpAluguel',
          align: 'start'
        },
        {
          text: 'Cliente',
          value: 'idCliente_nome',
          align: 'start'
        },
        {
          text: 'Data',
          value: 'data',
          align: 'start'
        },
        {
          text: 'Tipo Locação',
          value: 'idLocacao_descricao',
          align: 'start'
        },
        {
          text: 'Quadra',
          value: 'idQuadra_descricao',
          align: 'start'
        },
        {
          text: 'Horario início',
          value: 'horarioIni',
          align: 'start'
        },
        {
          text: 'Valor',
          value: 'valAluguel',
          align: 'start'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'start'
        },
        {
          text: 'Dias',
          value: 'dias',
          align: 'start'
        },
        {
          text: 'Fatura',
          value: 'idFatura',
          align: 'start'
        },
      ],
    }
  },
  computed: {
    selectedItemName() {
      return this.selectedItem?.idLocacao_descricao
    },
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'pageOptions.page': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.refresh(newValue);
      }
    }
  },
  methods: {
    corTipoAluno(tipo){
      if (tipo=="Titular"){
          return "titular";
      } else {
          return "dependente";
      }
    },
    dadosfatura(item) {
      if (item.tpAluguel === 'M') return '--';
      if (parseInt(item.idFatura) === 0) return 'Sem Fatura';
      if (item.dtPgto === "") return "Aberta";
      return "Paga em: "+ moment(item.dtPgto).format('DD/MM/YYYY');
    },
    showMenu(event, item) {
      this.selectedItem = !item.item ? item : item.item;
      this.$refs.menu.open(event);
    },
    onClickRow(row) {
      this.$emit('click:row', row);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAllClienteLogado(this.pageOptions.page, this.searchValues);

        this.itemsPerPage = page.elementsPerPage;
        this.totalElements = page.totalElements;
        this.elements = page.elements;
        this.pageOptions.page = page.currentPage;

      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.pageOptions.page = 1;
    this.refresh();
  }
}
</script>

<style scoped lang="scss">
.list-item {
  &::v-deep {
    .v-list-item__content {
      max-width: calc(100vw - 152px);
    }
  }
}
.list-item:not(:last-child) {
  border-bottom: 1px solid #dedede
}

</style>