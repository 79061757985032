<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <div>
      <meus-aluguels-data-table ref="dataTable"
                             :searchValues="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import MeusAluguelsDataTable from "../../components/shared/datatables/MeusAluguelsDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/aluguel";
import {findAllClienteLogadoMaisSeusDependentes as findAllCliente} from '../../api/clientes';
import {findAll as findAllHorario} from '../../api/horarios';
import {findAll as findAllQuadra} from '../../api/quadras';
import CreateEditAluguelDialog from "../../components/shared/dialogs/CreateEditAluguelDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";
import * as moment from 'moment';

export default {
  name: "index",
  components: {CreateEditAluguelDialog, BaseView, MeusAluguelsDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      searchValues: {},
      searchColumns: [
        {
          name: 'De',
          column: 'dataIni',
          type: 'date',
          defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
        },
        {
          name: 'Até',
          column: 'dataFim',
          type: 'date',
          defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
        },
        {
          name: 'Tipo Locação',
          column: 'tpAluguel',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.TIPO_ALUGUEL
          })
        },
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          name: 'Quadra',
          column: 'idQuadra',
          type: 'select',
          optionsFactory: () => findAllQuadra(1)
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.descricao
              })))
        },
        {
          name: 'Horario',
          column: 'idHorario',
          type: 'select',
          optionsFactory: () => findAllHorario(1)
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.range
              })))
        },
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        dia: [],
        status: 'A'
      };
      this.editDialogOpened = true;
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>