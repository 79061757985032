<template>
  <section>
    <v-row>
      <v-col cols="6">
        <lookup-dominio v-show="value.id === 0 || value.id == null" :radio="true" label="* Tipo Locação" :hidden-refresh="true" :hidden-clear="true" v-model="value.tpAluguel"
                        :type="lookups.tipoAluguel" :rules="[
            this.$validators.string.required
          ]" />
      </v-col>
      <v-col cols="6">
        <lookup-dominio v-show="(value.id === 0 || value.id == null) && value.tpAluguel === 'M'" :radio="true" label="* Locar para" :hidden-refresh="true" :hidden-clear="true" v-model="value.locPara"
                        :type="lookups.locarPara" />
      </v-col>
      <v-col cols="12">
        <search-box v-show="exibeComboCliente" :disabled="value.tpAluguel === 'A' && selecionouClienteAvulso"
                    label="* Cliente"
                    popup-label="Selecione um Cliente" :id.sync="value.idCliente"
                    :nome.sync="value.idCliente_nome" :value="cliente" :loader-fn="loaderClientes" item-key="id" item-text="nome"
          >
          <template v-slot:empty>
            <p class="ma-2">
              Nenhum Cliente encontrado
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12">
        <search-box v-show="exibeComboGrupoLocacao" 
                    label="* Grupo Locação"
                    popup-label="Selecione um  Grupo Locação" :id.sync="value.idGrupoLocacao"
                    :nome.sync="value.idGrupoLocacao_nome" :value="grupoLocacao" :loader-fn="loaderGrupoLocacao" item-key="id" item-text="nome"
          >
          <template v-slot:empty>
            <p class="ma-2">
              Nenhum Grupo de Locação encontrado
            </p>
          </template>
        </search-box>
      </v-col>

      <v-col cols="12">
        <v-text-field v-show="exibeInputCliAvulso" :disabled="value.tpAluguel === 'A' && parseInt(value.idCliente) > 0 && !selecionouClienteAvulso"
                      label="* Cliente Avulso" dense type="text"  v-model.trim="value.clienteAvulso" />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="* Data" dense type="date" :rules="[
          this.$validators.string.required,
        ]" v-model.trim="value.data" />
      </v-col>
      <v-col cols="12" md="4">
        <search-box label="* Quadra" popup-label="Selecione uma quadra" 
                    :hidden-clear="true"
                    :id.sync="value.idQuadra"
                    :descricao.sync="value.idQuadra_descricao" :value="quadra" :loader-fn="loaderQuadra" item-key="id"
                    item-text="descricao" :rules="[
            this.$validators.notNullOrUndefined
          ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhuma quadra encontrada
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="4"> <!--:preco.sync="value.valAluguel"-->
        <search-box label="* Periodo da Locação" popup-label="Locação"
                    :hidden-clear="true"
                    :emit-all-properties="true"
                    :id.sync="value.idLocacao"
                    :descricao.sync="value.idLocacao_descricao" 
                    :value="locacao"
                    :loader-fn="loaderTipoLocacao" item-key="id"
                    item-text="descricao" :rules="[
                      this.$validators.notNullOrUndefined
                    ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhuma quadra encontrada
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field dense label="* Preço" prefix="R$" :rules="[
          this.$validators.number.required,
          v => this.$validators.number.greaterThanOrEquals(v, 0)
        ]" v-model="value.valAluguel" type="number" />
      </v-col>
      <v-col cols="12" md="4">
        <search-box label="* Horario de início" 
                    popup-label="Selecione um horario"
                    :hidden-clear="true"
                    :horaInicio.sync="value.horarioIni"
                    :id.sync="value.idHorario"
                    :value="horario"
                    :loader-fn="loaderHorario"
                    item-key="id"
                    item-text="horaInicio" :rules="[
                      this.$validators.notNullOrUndefined
                    ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhum horario encontrado
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="4">
        <lookup-dominio label="* Status" :hidden-refresh="true" :hidden-clear="true"
                        v-model="value.status"
                        :type="lookups.status" :rules="[
            this.$validators.string.required
          ]">
        </lookup-dominio>
      </v-col>      
      <v-col cols="12" md="6" v-if="value.tpAluguel === 'M'">
        <lookup-dominio label="* Dia da semana" 
                        :hidden-refresh="true" 
                        :hidden-clear="true"
                        v-model="value.dia"
                        :multiple="true"
                        :chip="true"
                        :type="lookups.diaSemana" 
                        :rules="[
                          this.$validators.string.required,
                        ]">
        </lookup-dominio>
      </v-col>
      <v-col cols="12" md="6" v-if="value.tpAluguel === 'M'">
        <v-text-field label="Dia Vencimento"
                      :rules="[
                        this.$validators.number.required,
                        v => this.$validators.number.greaterThanOrEquals(v, 1),
                        v => this.$validators.number.lessThanOrEquals(v, 28)
                      ]"
                      v-model="value.diaVencimento" type="number"/>
      </v-col>
    </v-row>
  </section>
</template>
<script>

import SearchBox from "../common/SearchBox";
import { findAll as findAllClientes } from "../../../api/clientes";
import { findAll as findAllGrupoLocacao } from "../../../api/grupoLocacao";
import { findAll as findAllQuadras } from '../../../api/quadras';
import { findAll as findAllHorarios } from '../../../api/horarios';
import { findAll as findAllTipoLocacao } from '../../../api/locacoes';
import LookupDominio from "../common/LookupDominio";
import { ItemDeDominio } from "../../../api/itemsDominio";

export default {
  name: "AluguelForm",
  components: { LookupDominio, SearchBox },
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        diaSemana: ItemDeDominio.ARR_DIAS_SEMANA,
        status: ItemDeDominio.STATUS_PADRAO,
        tipoAluguel: ItemDeDominio.TIPO_ALUGUEL,
        locarPara: ItemDeDominio.LOCAR_PARA 
      },
      exibeComboCliente: false,
      exibeComboGrupoLocacao: false,
      exibeInputCliAvulso: false,
      status: [
        {
          id: 'A',
          text: 'Ativo'
        },
        {
          id: 'I',
          text: 'Inativo'
        }
      ]
    }
  },
  computed: {
    selecionouClienteAvulso() {
      return this.value.clienteAvulso != null && this.value.clienteAvulso.trim() !== '';
    },
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.idCliente_nome
      }
    },
    grupoLocacao() {
      if (this.value.idGrupoLocacao == null) return null;
      return {
        id: this.value.idGrupoLocacao,
        nome: this.value.idGrupoLocacao_nome
      }
    },
    clienteAvulso() {
      if (this.value.clienteAvulso == null) return null;
      return {
        clienteAvulso: this.value.clienteAvulso,
      }
    },
    quadra() {
      if (this.value.idQuadra == null) return null;
      return {
        id: this.value.idQuadra,
        descricao: this.value.idQuadra_descricao
      }
    },

    locacao() {
      if (this.value.idLocacao == null) return null;
      return {
        id: this.value.idLocacao,
        descricao: this.value.idLocacao_descricao
      }
    },
    horario() {
      if (this.value.idHorario == null) return null;
      return {
        id: this.value.idHorario,
        horaInicio: this.value.horarioIni
      }
    }
  },
  watch: {
    'value.tpAluguel': {
      handler(v) {
        this.controlaClienteCadastroEdicao();
        if(v === 'M') {
          this.value.clienteAvulso = '';
        }
        if(v === 'A') {
          this.value.dia = [];
        }
      }
    },
    'value.locPara': {
      handler(v) {
        this.controlaClienteCadastroEdicao();
      }
    },
  },
  methods: {
      controlaClienteCadastroEdicao(){
        this.exibeComboCliente = false;
        this.exibeComboGrupoLocacao = false;
        this.exibeInputCliAvulso = false;        
        if (this.value.tpAluguel === 'M'){
          if (this.value.locPara === 'C') this.exibeComboCliente = true;
          if (this.value.locPara === 'G') this.exibeComboGrupoLocacao = true;
        } else if (this.value.tpAluguel === 'A'){
          this.exibeComboCliente = true;
          this.exibeInputCliAvulso = true;
        }
      },
      updateRange(range) {
        this.value.hora_inicio_idHorario = range ? range.horaInicio : null;
      },
      loaderTipo(page, search) {
        return findAll(page, {
          descricao: search
        })
      },
      loaderQuadra(page, search) {
        return findAllQuadras(page, {
          descricao: search
        })
      },
      loaderClientes(page, search) {
        return findAllClientes(page, {
          nome: search
        })
      },
      loaderGrupoLocacao(page, search) {
        return findAllGrupoLocacao(page, {
          nome: search
        })
      },
      loaderHorario(page, search) {
        return findAllHorarios(page, {
          horaInicio: search
        });
      },
      loaderTipoLocacao(page, search) {
        return findAllTipoLocacao(page, {
          tipoLocacao: search
        })
      }
  },
  mounted() {
    if (this.value?.id > 0 && this.value?.tpAluguel === "M"){
      this.value.dia = this.value.dias.map(d => d.dia);
    } else {
      this.value.dia = [];
    }
    this.controlaClienteCadastroEdicao();
  }
}
</script>

<style scoped>
</style>